@keyframes rotateElement {
  0%   { transform: rotate(0); }
  100% { transform: rotate(-360deg); }
}

@keyframes changeColor {
  0%   {
    opacity: 0;
  }
  50%   {
    opacity: 1;
  }
  100%   {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33, 150, 243, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0);
  }
}
