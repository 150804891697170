.process-grid {
  background-color: #fff;
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  font-size: 14px;
  gap: 2px;

  &__card {
    width: 1fr;
    height: 300px;
    padding: .25em;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: .25em;
    cursor: pointer;

    &:nth-child(even) {
      background-color: #eeeeee;
    }

    &__header {
      font-weight: bold;
      display: flex;

      &__status {
        display: inline-block;
        background-color: rgb(30, 166, 211);
        border-radius: 50%;
        height: 1em;
        width: 1em;
        margin-right: 1em;
      }

      &__title {
        flex-grow: 1;
      }
    }

    &__content {
      overflow-y: auto;

      &.animate {
        animation: flash 2s ease-out forwards;
      }
    }
  }
}

@keyframes flash {
  from {
    background-color: #6d6d6d;
  }
}